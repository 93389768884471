import React from 'react';
import SolutionPage from '../../components/SolutionPage';

const data = {
    heroSection: {
        label: {
            title: 'Defect Detection', 
            imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/defect-detection-label-logo.png', 
            imgDimensions: {
                width: '0.875rem', 
                height: '0.875rem'
            }, 
            mobileImgDimensions: {
                width: '0.562rem', 
                height: '0.562rem' 
            }
        }, 
        heading: [
            {
                text: '100% visual inspection', 
                textColor: 'blue-800'
            },
            {
                text: '& lower cost of quality', 
                textColor: 'black'
            }
        ], 
        content: 'Augment the capabilities of your workforce to catch more defects quickly and accurately using customized AI software and off-the-shelf hardware.', 
        videoSrc: 'https://www.youtube.com/embed/EE3BDZYb4A8'
    }, 
    problemsSection: {
        heading: 'Problems with manual defect detection', 
        content: [
            {
                title: ['Human Errors'], 
                text: 'Monotonous visual inspection causes operator fatigue and errors', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/human-error-icon.png', 
                imgDimensions: {
                    width: '3.812rem', 
                    height: '3.812rem'
                }
            },
            {
                title: ['Slow'], 
                text: 'Mission critical parts require double and triple inspection checks', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/slow-icon.png', 
                imgDimensions: {
                    width: '3.812rem', 
                    height: '2.43rem'
                }
            },
            {
                title: ['Labor Risks'], 
                text: 'Susceptibility to labor shortage and knowledge loss from employee turnover', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/labor-risk-icon.png', 
                imgDimensions: {
                    width: '3.812rem', 
                    height: '3.812rem'
                }
            }
        ]   
    }, 
    outcomesSection: {
        content: [
            {
                title: 'Lower costs', 
                text: 'Reduce cost of quality by lowering error rate and removing redundancy checks', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/lower-cost-icon.png', 
                imgDimensions: {
                    width: '3.72rem', 
                    height: '3.48rem'
                }
            },
            {
                title: '100% inspection', 
                text: 'Fast software assisted checks make it feasible to visually inspect all parts', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/inspection-icon.png', 
                imgDimensions: {
                    width: '3.18rem', 
                    // height: '3.15rem'
                    height: '3.48rem'
                }
            },
            {
                title: 'Skill retention', 
                text: 'Inspection know-how is preserved in software even if employees leave', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/skill-retention-icon.png', 
                imgDimensions: {
                    width: '4.25rem', 
                    // height: '3.43rem'
                    height: '3.48rem'
                }
            },
        ]
    },
    automateSection: {
        heading: ['Automate quality checks with', ' AI-assisted defect detection'], 
        content: [
            {
                id: '01', 
                title: 'Customized for your parts', 
                text: [
                    "Loopr's camera-based software is customized to identify the exact defects which occur in your parts",
                    'Detect scratches, cracks, bent tips, incomplete weld, short circuits, missing paint and anything else which a human inspector can identify'
                ], 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/defect-detection-automate-01.png'
            },
            {
                id: '02', 
                title: 'Accurate results each time', 
                text: [
                    'Defects are identified in real-time and part under inspection is marked as GOOD/BAD on a screen',
                    "Get consistent and quick inspection results for every part, regardless of your quality operator's experience or focus level"
                ], 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/defect-detection-automate-02.png'
            },
            {
                id: '03', 
                title: 'Continuous Feedback', 
                text: [
                    'Defect data for each part is stored on your database, so you can track and analyze for root cause failures',
                    'AI Software is periodically updated with new defect data which continuously improves its accuracy'
                ], 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/defect-detection-automate-03.png'
            },
        ]
    }, 
    seeItInActionSection: true, 
    industries: ['Medical Devices', 'Semiconductors', 'Aerospace']
}

export default function DefectDetectionPage(){
    return  <SolutionPage data={data} />
}